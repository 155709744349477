import { Component, HostListener } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { LoaderService } from "./_services/loader.service";
import { MatButtonModule } from "@angular/material/button";
import { NgIf, NgOptimizedImage } from "@angular/common";
import { AsyncPipe } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [RouterOutlet, MatButtonModule, NgIf, AsyncPipe, NgOptimizedImage],
})
export class AppComponent {
  contentText: any;
  showLoader = new BehaviorSubject<boolean>(false);
  loading$ = this.showLoader.asObservable();
  result: any;
  /**
   * Constructor
   */
  constructor(private loaderService: LoaderService) {
    this.loaderService.status.subscribe((val: boolean, text: string) => {
      this.result = val;
      setTimeout(() => {
        this.showLoader.next(this.result.value);
      });
      this.contentText = this.result.text;
    });
  }
  ngOnInit(): void {
    // const localstorage = {
    //   _tid: "235610832409526273@connectsecure",
    //   showGraph: "1",  
    //   lastTime: "0",
    //   someMoney: "some money",
    //   open: "0",
    //   "zitadel:id_token":
    //     '"eyJhbGciOiJSUzI1NiIsImtpZCI6IjI0Nzc4NTE0ODI4MjU2ODcwNSIsInR5cCI6IkpXVCJ9.eyJhbXIiOlsicGFzc3dvcmQiLCJwd2QiXSwiYXRfaGFzaCI6IlFNWmRabXBQb2tvb09PNkFEblBRNWciLCJhdWQiOlsiMjM1NjEwODMyNDA5NTI2MjczQGNvbm5lY3RzZWN1cmUiLCIyMzU2MTA4MzcwNDAwMzc4ODlAY29ubmVjdHNlY3VyZSIsIjIzNTYxMDgyOTIwNTAxMjQ4MSJdLCJhdXRoX3RpbWUiOjE3MDQyMDU0NDYsImF6cCI6IjIzNTYxMDgzMjQwOTUyNjI3M0Bjb25uZWN0c2VjdXJlIiwiY19oYXNoIjoiZ0M3VGNzYl92NzU1V290b0dJd0RsZyIsImNsaWVudF9pZCI6IjIzNTYxMDgzMjQwOTUyNjI3M0Bjb25uZWN0c2VjdXJlIiwiZW1haWwiOiJhbnVAbGVhcm4tY29uc2VjdXJlLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJleHAiOjE3MDQyNDg2NjAsImZhbWlseV9uYW1lIjoiUCIsImdpdmVuX25hbWUiOiJBbnUiLCJpYXQiOjE3MDQyMDU0NTgsImlzcyI6Imh0dHBzOi8vYXV0aHoubXljb25uZWN0c2VjdXJlLmNvbSIsImxvY2FsZSI6bnVsbCwibmFtZSI6IkFudSBQIiwibm9uY2UiOiJYMjVrWTBwVkxrWm9ieTVyWmxCeWFHOVdiR0ZoVm1GalRrRldORXRQTW1obFJWWnFkR3AxVVdneGQxWXUiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJhbnVAbGVhcm4tY29uc2VjdXJlLmNvbSIsInN1YiI6IjI0NDAyNDQxMzUzMDM1Nzc2MSIsInVwZGF0ZWRfYXQiOjE3MDE5NDU4ODYsInVybjp6aXRhZGVsOmlhbTpvcmc6cHJvamVjdDoyMzU2MTA4MjkyMDUwMTI0ODE6cm9sZXMiOnsiYWRtaW4iOnsiMjM1NjEwODI1MzYzMDMwMDE3IjoibGVhcm4tY29uc2VjdXJlLmF1dGh6Lm15Y29ubmVjdHNlY3VyZS5jb20ifX0sInVybjp6aXRhZGVsOmlhbTpvcmc6cHJvamVjdDpyb2xlcyI6eyJhZG1pbiI6eyIyMzU2MTA4MjUzNjMwMzAwMTciOiJsZWFybi1jb25zZWN1cmUuYXV0aHoubXljb25uZWN0c2VjdXJlLmNvbSJ9fSwidXJuOnppdGFkZWw6aWFtOnVzZXI6cmVzb3VyY2Vvd25lcjppZCI6IjIzNTYxMDgyNTM2MzAzMDAxNyIsInVybjp6aXRhZGVsOmlhbTp1c2VyOnJlc291cmNlb3duZXI6bmFtZSI6ImxlYXJuLWNvbnNlY3VyZSIsInVybjp6aXRhZGVsOmlhbTp1c2VyOnJlc291cmNlb3duZXI6cHJpbWFyeV9kb21haW4iOiJsZWFybi1jb25zZWN1cmUuYXV0aHoubXljb25uZWN0c2VjdXJlLmNvbSJ9.fq-NlS18B6E92EOR8INjYrGbqu--DkDxlsZJbIK6MvXdBkOzP92BEz-O2tIMY1tCQy7yDEIwSNn0zUSbQhPYXdzTzOXY-kq4SS9AOMM4AiF0VFiFAWWCSNooYgGbAizIgO88pYH_QqvIYQDZTroCf5Xt_k5cZtx1ujbs0iV_27wRjRSPlwIcIZopcSl_qDz5wnOAZNNxR1I7C_ULUnePNO8utZPvRngiG5mqcWEG564us4gzeCuh_bDrP7tKkLSEZLwjfnFDoSQFuldt5Cp6rmA18w9rwNByrgiJpEVIpyxwOZxbys3jQPzzTqAEHWPtWHVQoSfd4VQZq0nsiVLQgw"',
    //   "zitadel:expires_at": '"1704248660721"',
    //   "zitadel:id_token_stored_at": '"1704205460723"',
    //   _pid: "pod700",
    //   company_overview: "",
    //   "zitadel:session_state": "undefined",
    //   "zitadel:id_token_claims_obj":
    //     '"{\\"amr\\":[\\"password\\",\\"pwd\\"],\\"at_hash\\":\\"QMZdZmpPokooOO6ADnPQ5g\\",\\"aud\\":[\\"235610832409526273@connectsecure\\",\\"235610837040037889@connectsecure\\",\\"235610829205012481\\"],\\"auth_time\\":1704205446,\\"azp\\":\\"235610832409526273@connectsecure\\",\\"c_hash\\":\\"gC7Tcsb_v755WotoGIwDlg\\",\\"client_id\\":\\"235610832409526273@connectsecure\\",\\"email\\":\\"anu@learn-consecure.com\\",\\"email_verified\\":true,\\"exp\\":1704248660,\\"family_name\\":\\"P\\",\\"given_name\\":\\"Anu\\",\\"iat\\":1704205458,\\"iss\\":\\"https://authz.myconnectsecure.com\\",\\"locale\\":null,\\"name\\":\\"Anu P\\",\\"nonce\\":\\"X25kY0pVLkZoby5rZlByaG9WbGFhVmFjTkFWNEtPMmhlRVZqdGp1UWgxd1Yu\\",\\"preferred_username\\":\\"anu@learn-consecure.com\\",\\"sub\\":\\"244024413530357761\\",\\"updated_at\\":1701945886,\\"urn:zitadel:iam:org:project:235610829205012481:roles\\":{\\"admin\\":{\\"235610825363030017\\":\\"learn-consecure.authz.myconnectsecure.com\\"}},\\"urn:zitadel:iam:org:project:roles\\":{\\"admin\\":{\\"235610825363030017\\":\\"learn-consecure.authz.myconnectsecure.com\\"}},\\"urn:zitadel:iam:user:resourceowner:id\\":\\"235610825363030017\\",\\"urn:zitadel:iam:user:resourceowner:name\\":\\"learn-consecure\\",\\"urn:zitadel:iam:user:resourceowner:primary_domain\\":\\"learn-consecure.authz.myconnectsecure.com\\"}"',
    //   cmp: "*",
    //   "zitadel:access_token_stored_at": '"1704205460721"',
    //   _tInfo:
    //     "eyJjbGllbnRfaWQiOiIyMzU2MTA4MzI0MDk1MjYyNzNAY29ubmVjdHNlY3VyZSIsInBvZF9pZCI6InBvZDcwMCIsInRlbmFudF9pZCI6IjIzNTYxMDgyNTM2MzAzMDAxNyJ9",
    //   "zitadel:PKCE_verifier":
    //     '"cWFfU1JvWkdtMFdLZ2IxZldlTFVJNlZlMXpYSXd0emhBNWpFVVlld3F2WUJC"',
    //   loaded: "0",
    //   initLogin: "1",
    //   "zitadel:access_token":
    //     '"Nb8OHnVd4t0jNUTtT99VErob-ZmLRLVF0S3_j7CuCZCuXBB_OsUA-rWDF38wk7_tHxQamyY"',
    //   xtid: "235610825363030017",
    //   "zitadel:id_token_expires_at": '"1704248660000"',
    //   "zitadel:nonce":
    //     '"QWlzbXltZWtuNXZ1SGE0c2V1TjJpVk5keEFvNG5iU211MXh5YTFMRUk3RWlm"',
    // };

    // Object.keys(localstorage).forEach(function (key) {
    //   var value = localstorage[key];
    //   localStorage.setItem(key, JSON.stringify(value));
    // });
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.loaderService.display(false);
      this.loaderService.Modeldisplay(false);
    }
  }
}
