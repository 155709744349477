import { Route } from "@angular/router";
import { initialDataResolver } from "app/app.resolvers";
import { AuthGuard } from "app/core/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/reports'
  {
    path: "",
    pathMatch: "full",
    redirectTo: "summary",
  },

  // Redirect signed-in user to the '/reports'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  /*{
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'overview',
    },*/

  // Auth routes for guests
  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-in",
        loadChildren: () => import("app/modules/auth/sign-in/sign-in.routes"),
      },
      {
        path: "login",
        loadChildren: () =>
          import("app/modules/oauth2/oauth2-routing.module").then(
            (m) => m.Oauth2RoutingModule
          ),
      },
      {
        path: "sign-up",
        loadChildren: () => import("app/modules/auth/sign-up/sign-up.routes"),
      },
      {
        path: "success",
        loadChildren: () =>
          import("app/modules/auth/auth-grant/auth-grant.routes"),
      },
      {
        path: "auth-grant",
        loadChildren: () =>
          import("app/modules/auth/auth-grant/auth-grant.routes"),
      },
      // {
      //   path: "partner-management",
      //   loadChildren: () =>
      //     import(
      //       "app/modules/admin/partner-management/partner-management.routes"
      //     ),
      // },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-out",
        loadChildren: () => import("app/modules/auth/sign-out/sign-out.routes"),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      // {
      //   path: "partner-management",
      //   data: { breadcrumb: { alias: "Partner Management" } },
      //   runGuardsAndResolvers: "always",
      //   children: [
      //     {
      //       path: "all-partners",
      //       data: {
      //         breadcrumb: { alias: "All Partners", is_deprecated: false },
      //       },
      //       loadChildren: () =>
      //         import(
      //           "app/modules/admin/partner-management/all-partners/all-partners.routes"
      //         ),
      //     },
      //     {
      //       path: "trial-partners",
      //       data: { breadcrumb: { alias: "Trial Partners" } },
      //       loadChildren: () =>
      //         import(
      //           "app/modules/admin/partner-management/trial-partners/trial-partners.routes"
      //         ),
      //     },
      //     {
      //       path: "plan-partners",
      //       data: { breadcrumb: { alias: "Plan Partners" } },
      //       loadChildren: () =>
      //         import(
      //           "app/modules/admin/partner-management/plan-partners/plan-partners.routes"
      //         ),
      //     },
      //     {
      //       path: "paid-partners",
      //       data: { breadcrumb: { alias: "Paid Partners" } },
      //       loadChildren: () =>
      //         import(
      //           "app/modules/admin/partner-management/paid-partners/paid-partners.routes"
      //         ),
      //     },
      //     {
      //       path: "suspended-partners",
      //       data: { breadcrumb: { alias: "Suspended Partners" } },
      //       loadChildren: () =>
      //         import(
      //           "app/modules/admin/partner-management/suspended-partners/suspended-partners.routes"
      //         ),
      //     },
      //     {
      //       path: "cancelled-partners",
      //       data: { breadcrumb: { alias: "Cancelled Partners" } },
      //       loadChildren: () =>
      //         import(
      //           "app/modules/admin/partner-management/cancelled-partners/cancelled-partners.routes"
      //         ),
      //     },
      //   ],
      // },
      {
        path: "global-settings",
        data: { breadcrumb: { alias: "Global Settings" } },
        children: [
          {
            path: "partner-users",
            data: {
              breadcrumb: { alias: "Users", is_deprecated: false },
            },
            loadChildren: () =>
              import(
                "app/modules/admin/global-settings/partner-users/partner-users.routes"
              ),
          },
          {
            path: "partner-distributors",
            data: { breadcrumb: { alias: "Distributors" } },
            loadChildren: () =>
              import(
                "app/modules/admin/global-settings/partner-distributors/partner-distributors.routes"
              ),
          },
          {
            path: "partner-roles",
            data: { breadcrumb: { alias: "Roles" } },
            loadChildren: () =>
              import(
                "app/modules/admin/global-settings/partner-roles/partner-roles.routes"
              ),
          },
          {
            path: "partner-audit",
            data: { breadcrumb: { alias: "Audit Logs" } },
            loadChildren: () =>
              import(
                "app/modules/admin/global-settings/partner-audit/partner-audit.routes"
              ),
          },
          {
            path: "partner-settings",
            data: { breadcrumb: { alias: "Settings" } },
            loadChildren: () =>
              import(
                "app/modules/admin/global-settings/partner-settings/partner-settings.routes"
              ),
          },
        ],
      },
      {
        path: "add-partners",
        data: { breadcrumb: { alias: "Add Partners" } },
        loadChildren: () =>
          import("app/modules/admin/add-partners/add-partners.routes"),
      },
      {
        path: "summary",
        data: { breadcrumb: { alias: "Summary" } },
        loadChildren: () => import("app/modules/admin/summary/summary.routes"),
      },
      {
        path: "dashboard",
        data: { breadcrumb: { alias: "Dashboard" } },
        loadChildren: () =>
          import("app/modules/admin/dashboard/dashboard.routes"),
      },
      {
        path: "partner-management",
        data: { breadcrumb: { alias: "Partner Management" } },
        loadChildren: () =>
          import(
            "app/modules/admin/partner-management/partner-management/partner-management.routes"
          ),
      },
      // dashboard
    ],
  },
  {
    path: "**",
    redirectTo: "summary",
  },
];
