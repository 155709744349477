<button mat-icon-button (click)="openaddPanel()" matTooltip="Add New Partner">
  <mat-icon [svgIcon]="'heroicons_mini:user-plus'"></mat-icon>
</button>

<s-modal id="addPartnerModal">
  <mat-card class="modal w-[700px]">
    <form class="flex flex-col px-4 py-4 bg-card shadow rounded overflow-hidden" #partnerNgForm="ngForm">
      <mat-card-header>
        <mat-card-title class="text-lg font-medium">Add Partner</mat-card-title>
        <div class="spacer">&nbsp;</div>
        <button class="mini-form-field" mat-icon-button aria-label="close modal icon" matTooltip="Close"
          (click)="_modalService.close('addPartnerModal');addPartnersData={}">
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="my-4" fuseScrollbar>
        <div class="grid grid-cols-2 gap-4">
          <mat-form-field class="w-full mb-4">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Add Email" type="email" required name="email"
              [(ngModel)]="addPartnersData.email" />
          </mat-form-field>
          <mat-form-field class="w-full mb-4">
            <mat-label>Company</mat-label>
            <input matInput placeholder="Add Company" type="text" required name="company"
              [(ngModel)]="addPartnersData.company" />
          </mat-form-field>
          <mat-form-field class="w-full mb-4">
            <mat-label>Tenant Name</mat-label>
            <input matInput placeholder="Add Tenant Name" type="text" name="TenantName"
              [(ngModel)]="addPartnersData.tenant_name" />
          </mat-form-field>
          <mat-form-field class="w-full mb-4">
            <mat-label>Mobile</mat-label>
            <input matInput placeholder="Add Mobile Number" type="numer" required name="mobile"
              [(ngModel)]="addPartnersData.mobile" />
          </mat-form-field>
          <mat-form-field class="w-full mb-4">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Add First Name" type="text" required name="firstName"
              [(ngModel)]="addPartnersData.first_name" />
          </mat-form-field>
          <mat-form-field class="w-full mb-4">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Add Last Name" type="text" required name="lastName"
              [(ngModel)]="addPartnersData.last_name" />
          </mat-form-field>
          <mat-form-field class="w-full mb-4">
            <mat-label>City</mat-label>
            <input matInput placeholder="Add City" type="text" required name="city"
              [(ngModel)]="addPartnersData.city" />
          </mat-form-field>
          <mat-form-field class="w-full mb-4">
            <mat-label>State</mat-label>
            <input matInput placeholder="Add State" type="text" required name="state"
              [(ngModel)]="addPartnersData.state" />
          </mat-form-field>
          <!-- <mat-form-field class="w-full mb-4">
            <mat-label>Deployment Model</mat-label>
            <mat-select
              class=""
              placeholder="Select Deployment Model"
              name="DeploymentModel"
              required
              [(ngModel)]="addPartnersData.deploymentModel"
            >
              <mat-option value="Separate server in SaaS Cloud"
                >Separate server in SaaS Cloud</mat-option
              >
              <mat-option value="Self Host in Other VM"
                >Self Host in Other VM
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          <mat-form-field class="w-full mb-4">
            <mat-label>Choose Region</mat-label>
            <mat-select class="" placeholder="Choose Region" name="ChooseRegion" required
              [(ngModel)]="addPartnersData.region">
              <mat-option *ngFor="let region of regions" [value]="region.name">{{ region.displayName }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-full mb-4">
            <mat-label>Choose Country</mat-label>
            <mat-select class="" placeholder="Choose Country" name="ChooseCountry" required
              [(ngModel)]="addPartnersData.country">
              <mat-option *ngFor="let country of countryList" [value]="country.displayName">{{ country.displayName
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field class="w-full mb-4">
            <mat-label>Sub Domain Name</mat-label>
            <input
              matInput
              placeholder="Add Sub Domain Name"
              type="text"
              required
              name="SubDomainName"
              [(ngModel)]="addPartnersData.subDomain"
            />
          </mat-form-field> -->
        </div>
        <!-- <div class="flex flex-col mt-4 gt-sm:mt-0 gt-sm:ml-16">
          <span class="font-semibold mb-2">Install Method</span>
          <mat-radio-group
            class="flex flex-row"
            [color]="'primary'"
            required
            name="aclCompanies"
            [(ngModel)]="addPartnersData.installMethod"
          >
            <mat-radio-button class="mr-2" value="remoteAccess">
              Yes, Partner will provide remote access.
            </mat-radio-button>
            <mat-radio-button class="mr-2" value="configure">
              No, Partner will configure himself.
            </mat-radio-button>
          </mat-radio-group>
        </div> -->
        <div class="flex items-center justify-end mt-4 p-2 border-t bg-gray-50 dark:bg-transparent ml-auto">
          <button class="ml-auto" mat-button [matTooltip]="'Cancel'"
            (click)="_modalService.close('addPartnerModal');addPartnersData={}">
            Cancel
          </button>
          <button class="ml-2" mat-flat-button [color]="'primary'" type="button"
            [disabled]="!(addPartnersData.email && addPartnersData.company &&  addPartnersData.tenant_name && addPartnersData.mobile && addPartnersData.first_name && addPartnersData.last_name && addPartnersData.country && addPartnersData.region && addPartnersData.state && addPartnersData.city) "
            appPreventMultiClick [matTooltip]="'Save'" (click)="SavePartner(addPartnersData)">
            Save
          </button>
        </div>
      </mat-card-content>
    </form>
  </mat-card>
</s-modal>