import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CommonModule, NgFor, NgIf, NgOptimizedImage } from "@angular/common";
import { GridsComponent } from "../../shared/grids/grids.component";
import { KeyPairsComponent } from "../../shared/key-pairs/key-pairs.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { Subject, Subscription, takeUntil } from "rxjs";
import { MaterialModule } from "../../../material.module";
import { AppFilterPipeModule } from "../../../_filters/app.filter-pipe.module";
import { TableComponent } from "../../shared/table/table.component";
import { ActivatedRoute } from "@angular/router";
import { BaseRequestService } from "../../../_services/base.service";
import { MyToastrService } from "../../../_services/toastr.service";
import { CommonService } from "../../../_services/common.service";
import { LoaderService } from "../../../_services/loader.service";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { FuseAlertComponent } from "../../../../@fuse/components/alert";
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";
import { DynamicSettingsService } from "app/layout/common/dynamic-settings/dynamic-settings.service";
import { ModalComponent } from "app/modules/shared/modal.component";
import { ModalService } from "app/_services/modal.service";
import { DynamicFormComponent } from "app/modules/shared/forms/dynamic-form/dynamic-form.component";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserService } from "app/core/user/user.service";
import { fuseAnimations } from "@fuse/animations";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-add-partners",
  standalone: true,
  imports: [
    CommonModule,
    GridsComponent,
    KeyPairsComponent,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    ModalComponent,
    TableComponent,
    FuseScrollbarDirective,
    MaterialModule,
    AppFilterPipeModule,
    DynamicFormComponent,
    FuseAlertComponent,
    NgIf,
    NgFor,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: "./add-partners.component.html",
  styleUrls: ["./add-partners.component.scss"],
})
export class AddPartnersComponent {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  regions: any;
  addPartnersData: any = {};
  countryList: any = [
    { displayName: "Afghanistan" },
    { displayName: "Albania" },
    { displayName: "Algeria" },
    { displayName: "Andorra" },
    { displayName: "Angola" },
    { displayName: "Antigua and Barbuda" },
    { displayName: "Argentina" },
    { displayName: "Armenia" },
    { displayName: "Australia" },
    { displayName: "Austria" },
    { displayName: "Azerbaijan" },
    { displayName: "Bahamas" },
    { displayName: "Bahrain" },
    { displayName: "Bangladesh" },
    { displayName: "Barbados" },
    { displayName: "Belarus" },
    { displayName: "Belgium" },
    { displayName: "Belize" },
    { displayName: "Benin" },
    { displayName: "Bhutan" },
    { displayName: "Bolivia" },
    { displayName: "Bosnia and Herzegovina" },
    { displayName: "Botswana" },
    { displayName: "Brazil" },
    { displayName: "Brunei" },
    { displayName: "Bulgaria" },
    { displayName: "Burkina Faso" },
    { displayName: "Burundi" },
    { displayName: "Cote dIvoire" },
    { displayName: "Cabo Verde" },
    { displayName: "Cambodia" },
    { displayName: "Cameroon" },
    { displayName: "Canada" },
    { displayName: "Central African Republic" },
    { displayName: "Chad" },
    { displayName: "Chile" },
    { displayName: "China" },
    { displayName: "Colombia" },
    { displayName: "Comoros" },
    { displayName: "Congo (Congo-Brazzaville)" },
    { displayName: "Costa Rica" },
    { displayName: "Croatia" },
    { displayName: "Cuba" },
    { displayName: "Cyprus" },
    { displayName: "Czechia (Czech Republic)" },
    { displayName: "Democratic Republic of the Congo" },
    { displayName: "Denmark" },
    { displayName: "Djibouti" },
    { displayName: "Dominica" },
    { displayName: "Dominican Republic" },
    { displayName: "Ecuador" },
    { displayName: "Egypt" },
    { displayName: "El Salvador" },
    { displayName: "Equatorial Guinea" },
    { displayName: "Eritrea" },
    { displayName: "Estonia" },
    { displayName: 'Eswatini (fmr. "Swaziland")' },
    { displayName: "Ethiopia" },
    { displayName: "Fiji" },
    { displayName: "Finland" },
    { displayName: "France" },
    { displayName: "Gabon" },
    { displayName: "Gambia" },
    { displayName: "Georgia" },
    { displayName: "Germany" },
    { displayName: "Ghana" },
    { displayName: "Greece" },
    { displayName: "Grenada" },
    { displayName: "Guatemala" },
    { displayName: "Guinea" },
    { displayName: "Guinea-Bissau" },
    { displayName: "Guyana" },
    { displayName: "Haiti" },
    { displayName: "Holy See" },
    { displayName: "Honduras" },
    { displayName: "Hungary" },
    { displayName: "Iceland" },
    { displayName: "India" },
    { displayName: "Indonesia" },
    { displayName: "Iran" },
    { displayName: "Iraq" },
    { displayName: "Ireland" },
    { displayName: "Israel" },
    { displayName: "Italy" },
    { displayName: "Jamaica" },
    { displayName: "Japan" },
    { displayName: "Jordan" },
    { displayName: "Kazakhstan" },
    { displayName: "Kenya" },
    { displayName: "Kiribati" },
    { displayName: "Kuwait" },
    { displayName: "Kyrgyzstan" },
    { displayName: "Laos" },
    { displayName: "Latvia" },
    { displayName: "Lebanon" },
    { displayName: "Lesotho" },
    { displayName: "Liberia" },
    { displayName: "Libya" },
    { displayName: "Liechtenstein" },
    { displayName: "Lithuania" },
    { displayName: "Luxembourg" },
    { displayName: "Madagascar" },
    { displayName: "Malawi" },
    { displayName: "Malaysia" },
    { displayName: "Maldives" },
    { displayName: "Mali" },
    { displayName: "Malta" },
    { displayName: "Marshall Islands" },
    { displayName: "Mauritania" },
    { displayName: "Mauritius" },
    { displayName: "Mexico" },
    { displayName: "Micronesia" },
    { displayName: "Moldova" },
    { displayName: "Monaco" },
    { displayName: "Mongolia" },
    { displayName: "Montenegro" },
    { displayName: "Morocco" },
    { displayName: "Mozambique" },
    { displayName: "Myanmar (formerly Burma)" },
    { displayName: "Namibia" },
    { displayName: "Nauru" },
    { displayName: "Nepal" },
    { displayName: "Netherlands" },
    { displayName: "New Zealand" },
    { displayName: "Nicaragua" },
    { displayName: "Niger" },
    { displayName: "Nigeria" },
    { displayName: "North Korea" },
    { displayName: "North Macedonia" },
    { displayName: "Norway" },
    { displayName: "Oman" },
    { displayName: "Pakistan" },
    { displayName: "Palau" },
    { displayName: "Palestine State" },
    { displayName: "Panama" },
    { displayName: "Papua New Guinea" },
    { displayName: "Paraguay" },
    { displayName: "Peru" },
    { displayName: "Philippines" },
    { displayName: "Poland" },
    { displayName: "Portugal" },
    { displayName: "Qatar" },
    { displayName: "Romania" },
    { displayName: "Russia" },
    { displayName: "Rwanda" },
    { displayName: "Saint Kitts and Nevis" },
    { displayName: "Saint Lucia" },
    { displayName: "Saint Vincent and the Grenadines" },
    { displayName: "Samoa" },
    { displayName: "San Marino" },
    { displayName: "Sao Tome and Principe" },
    { displayName: "Saudi Arabia" },
    { displayName: "Senegal" },
    { displayName: "Serbia" },
    { displayName: "Seychelles" },
    { displayName: "Sierra Leone" },
    { displayName: "Singapore" },
    { displayName: "Slovakia" },
    { displayName: "Slovenia" },
    { displayName: "Solomon Islands" },
    { displayName: "Somalia" },
    { displayName: "South Africa" },
    { displayName: "South Korea" },
    { displayName: "South Sudan" },
    { displayName: "Spain" },
    { displayName: "Sri Lanka" },
    { displayName: "Sudan" },
    { displayName: "Suriname" },
    { displayName: "Sweden" },
    { displayName: "Switzerland" },
    { displayName: "Syria" },
    { displayName: "Tajikistan" },
    { displayName: "Tanzania" },
    { displayName: "Thailand" },
    { displayName: "Timor-Leste" },
    { displayName: "Togo" },
    { displayName: "Tonga" },
    { displayName: "Trinidad and Tobago" },
    { displayName: "Tunisia" },
    { displayName: "Turkey" },
    { displayName: "Turkmenistan" },
    { displayName: "Tuvalu" },
    { displayName: "Uganda" },
    { displayName: "Ukraine" },
    { displayName: "United Arab Emirates" },
    { displayName: "United Kingdom" },
    { displayName: "United States of America" },
    { displayName: "Uruguay" },
    { displayName: "Uzbekistan" },
    { displayName: "Vanuatu" },
    { displayName: "Venezuela" },
    { displayName: "Vietnam" },
    { displayName: "Yemen" },
    { displayName: "Zambia" },
    { displayName: "Zimbabwe" },
  ];
  constructor(
    private cs: CommonService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private _ds: DynamicSettingsService,
    private _bs: BaseRequestService,
    public _modalService: ModalService,
    public _userService: UserService,
    private toast: MyToastrService,
    public confirmDialog: FuseConfirmationService
  ) { }
  ngOnInit(): void { }
  openaddPanel() {
    this._modalService.open("addPartnerModal");
    this.getRegions();
  }
  getRegions() {
    this._bs
      .doRequest(
        "https://configuration.myconnectsecure.com/api/getRegions",
        "get"
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          this.regions = result.message;
        } else {
          console.log("Error in getRegions");
        }
      });
  }
  SavePartner(partnerData: any) {
    partnerData.password = '';
    partnerData.backend = true;
    // partnerData.region = "ap-south-2"
    this.loaderService.display(true);
    this._bs
      .doRequest(
        "/w/partners/createpartner",
        "post",
        partnerData
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.toast.sToast('success', 'Partner Added successfully!');
          this._modalService.close('addPartnerModal');
          this.addPartnersData = {}
          this.cs.afterAddPartner.emit({})
        } else {
          const data = (result.message) ? result.message : result.data;
          this.toast.sToast('error', data);
        }
      });
  }
}
