import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { Injectable, WritableSignal, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observer, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import jwt_decode from "jwt-decode";
import {environment} from "../../environments/environment";

interface NetaResponse {
  status: string;
  msg: any;
}

@Injectable({ providedIn: 'root' })
export class BaseRequestService {
  cisv8: any = [];
  cyberLabel: any;
  isAssessment = false;
  showCompany = true;
  showAssessment = false;
  scoreCompanyHash: any = {};
  verifyWindow: any;
  o365window: any;
  authHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: btoa(window.location.host),
  });
  baseURL: any = environment.baseURL;
  configURL: any = environment.configURL;
  public authURL: any = environment.authURL;
  currentSite: any;
  currentCompany: any;
  resources: any = {};
  companyId: any;
  helpLinks: any;
  enckey: any;
  overlayLoadingTemplate =
      '<span class="">' +
      '<i class="fa fa-spinner fa-spin"></i> Please wait while your rows are loading</span>';
  companyList: any = [];
  companies$: BehaviorSubject<any>;
  user: WritableSignal<any> = signal({});
  whitelabel: WritableSignal<any> = signal(
      {
          logo_dark: environment.logo_dark,
          logo: environment.logo,
          icon: environment.icon,
          icon_dark: environment.icon_dark,
      }
  );

  Tabelsetting: WritableSignal<any> = signal(
      {
          font: 'small',
          page_size: '5'
      }
  );

  favIcon: HTMLLinkElement = document.querySelector('#_appImg');
  favIconDark: HTMLLinkElement = document.querySelector('#_appImgDark');
  constructor(
      readonly httpClient: HttpClient,
      private titleService: Title,
      private _userService: UserService,
      private router: Router,
  ) {
      setTimeout(() => {
          this.getWhiteLabelSettings();
          // this.getEncryptKey();
          // this.getResourcesData();
          this.getHelpLinks();
      }, 1000);
  }

  public redirect(url: string): any {
      this.router.navigateByUrl(url);
  }

  public upload(url: string, data: any): any {
      return this.httpClient
          .post<any>(url, data, {
              reportProgress: true,
              observe: 'events',
          })
          .pipe(
              map((event) => {
                  switch (event.type) {
                      case HttpEventType.UploadProgress:
                          // @ts-ignore
                          const progress = Math.round(
                              (100 * event.loaded) / event.total
                          );
                          return { status: 'progress', message: progress };
                      case HttpEventType.Response:
                          return event.body;
                      default:
                          return `Unhandled event: ${event.type}`;
                  }
              })
          );
  }

  keypress(event: any): any {
      return false;
  }
  public getClientData(): any {
      return this.httpClient.get('https://extreme-ip-lookup.com/json/');
  }
  private getEncryptKey(): void {
      this.doRequest(
          '/api/cyberutils/dummy/getEncryptionKey',
          'post',
          {},
          null,
          this.authHeader
      ).subscribe((data: any) => {
          this.enckey = atob(data.msg);
      });
  }
  public getHelpLinks(): void {
      if (
          !this.helpLinks &&
          localStorage.getItem('helpLinks') &&
          localStorage.getItem('helpLinks') !== null
      ) {
          this.helpLinks = JSON.parse(
              localStorage.getItem('helpLinks') || '{}'
          );
      } else {
          /* this.doRequest('/assets/text/help/links.json', 'get').subscribe(
              (data: any) => {
                  this.helpLinks = data;
                  localStorage.setItem('helpLinks', JSON.stringify(data));
              }
          ); */
      }
  }
  public getWhiteLabelSettings(): void {
      this.cyberLabel = {};
      this.cyberLabel.productName = environment.productName;
      this.cyberLabel.eulaLink = 'https://www.cybercns.com/terms';
      this.cyberLabel.agentLink =
          'https://netalyticsvulnerabilitydownload.s3-ap-southeast-1.amazonaws.com/ConnectSecureScanner.exe';
      /*this.doRequest('/assets/json/wlb.json', 'get').subscribe((data: {}) => {
        this.cyberLabel = data;
        this.titleService.setTitle( this.cyberLabel.productName );
      });*/
  }
  public getResourcesData(): void {
      this.doRequest('/assets/text/en-US/strings.json', 'get').subscribe(
          (data: {}) => {
              this.resources = data;
          }
      );
  }
  public setCurrentCompany(companyId: any): void {
      this.companyId = companyId;
  }
  public nonce(): number | string {
      let val = '';
      const hex = 'abcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 16; i++) {
          val += hex.charAt(Math.floor(Math.random() * hex.length));
      }
      return val;
  }

  public unsafePublish(topic: string, message: string): void {
      // this._mqttService.unsafePublish(topic, message, {qos: 0, retain: false});
  }

  getBaseURL() {
      return (window.location.host.indexOf('portal') > -1) ? this.baseURL : '';
  }
  toHttpParams(obj: any): any {
      const params = {};
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              const val = obj[key];
              if (val !== null && val !== undefined) {
                  if (typeof val === 'object') {
                      // @ts-ignore
                      params[key] = JSON.stringify(val);
                  } else {
                      // @ts-ignore
                      params[key] = val.toString();
                  }
              }
          }
      }
      return params;
  }

  deleteRequest(endPointUrl: string): any {
      return this.httpClient
          .delete<NetaResponse>(`${endPointUrl}`)
          .pipe(map((response) => this.handleResponse(response)));
  }

  containsSubstringFromArray(mainString: string, substringsArray: any): any {
      return substringsArray.some((substring: any) => mainString.includes(substring));
  }

  doRequest(
      endPointUrl: string,
      method: string,
      data?: any,
      params?: any,
      headers?: HttpHeaders,
      hashOptions?: any
  ): any {
      if (method.toLowerCase() === 'patch' || method.toLowerCase() === 'post' || method.toLowerCase() === 'put') {
          data = this.trimNested(data);
      }
      let pid = localStorage.getItem('_pid');
      const _params = Object.assign({}, params);
      /*DUCK DB Report query fix*/
      const reportApi = ['agent_discovery_credentials', 'agent_discovery_credentials_global', 'ad_gpos_details', 'get_groups_details',
          'application_baseline_rules_company', 'asset_security_report_data', 'assets_by_cis_compliance_for_company', 'asset_compliance_details',
          'compliance_check_count', 'compliance_check_count_by_section', 'compliance_check_asset_count', 'get_computer_details',
          'compliance_check_company_count', 'compliance_maturity', 'compliance_check_asset_count_by_section', 'ad_roles_member',
          'ad_groups_view', 'ad_ous_view', 'ad_computers_view', 'ad_users_view', 'ad_domain_details', 'get_ous_details', 'get_user_details',
          'asset_compliance_report_data', 'assessment_autofill', "count_by_os", "records_by_os", "count_by_os_server", "records_by_os_server",
          "count_of_firewall", "records_of_firewall", "check_antivirus", "remediation_for_assessment", "remediation_for_assessment_rem_prod_and_assetnames",
          "os_for_assessment", "os_for_assessment_with_assetnames_concat", "os_fourteen_days", "os_fourteen_days_with_assetnames",
          "software_fourteen_days_with_assetnames", "software_fourteen_days", "unique_office_products", "unique_office_products_with_assetnames",
          "unique_browsers", "unique_browsers_with_assetnames", "os_updates", "os_updates_values", "asset_users_admin_exists", "asset_users_admin_records", "ad_users_count", "enabled_ad_users_"
      ]

      if (endPointUrl.indexOf('/r/reports') > -1 && _params && _params.name && reportApi.indexOf(_params.name) === -1) {
          endPointUrl = endPointUrl.replace('/r/reports', `/report_queries/${_params.name}`)
          delete _params.name;
      }

      if (endPointUrl.indexOf('report_queries') > -1 && _params && _params.name && reportApi.indexOf(_params.name) === -1) {
          endPointUrl = endPointUrl.replace('/report_queries/', `/report_queries/${_params.name}`)
          delete _params.name;
      }
      /*DUCK DB Report query fix*/

      /*Support Ticket*/
      if (endPointUrl.indexOf('/api/tickets') > -1) {
          endPointUrl = endPointUrl.replace('/api/tickets', 'https://sts.myconnectsecure.com/api/tickets')
          delete _params.name;
      }
      /*Support Ticket*/

      const httpOptions = {
          headers: headers
              ? headers
              : new HttpHeaders({
                  'Content-Type': 'application/json',
              }),
          body: JSON.stringify(data),
          params: _params,
      };
      if (_params) {
          httpOptions.params = new HttpParams({
              fromObject: this.toHttpParams(_params),
          });
      }
      try {
          const token = JSON.parse(localStorage.getItem('zitadel:id_token'));
          let user = jwt_decode(token);
          let s = Object.keys(user["urn:zitadel:iam:org:project:roles"])
          let tenantid = Object.keys(user["urn:zitadel:iam:org:project:roles"][s[0]])[0];
          let a = {
              "tenant": tenantid,
              "tenant_name": user['urn:zitadel:iam:user:resourceowner:name'],
              "sub": user['sub'],
              "email": user['email'],
              "first_name": user['given_name'],
              "last_name": user['family_name'],
              "exp": Math.floor((Date.now() + 120 * 60 * 1000) / 1000),
          }
          try {
              const secondLevelKey = Object.keys(user['urn:zitadel:iam:org:project:roles'])[0];
              const role = user['urn:zitadel:iam:org:project:roles'][secondLevelKey];
              const thirdLevelKey = Object.keys(role)[0];
              const domain = role[thirdLevelKey];
              const parts = domain.split('.');
              a['tenant_name'] = parts[0];

          } catch (error) {
              console.error('Error parsing JSON:', error);
          }
          localStorage.setItem('xtid', tenantid);
          this.user.set(a);
          if (!httpOptions.headers.has('AUTHORIZATION') && token) {
              httpOptions.headers = httpOptions.headers.append(
                  'AUTHORIZATION',
                  `Bearer ${token}`
              );
          }
          let tid = localStorage.getItem('xtid');
          if (!httpOptions.headers.has('X-TENANT-ID') && token) {
              httpOptions.headers = httpOptions.headers.append(
                  'X-TENANT-ID',
                  tid
              );
          }
      } catch (e) {
          console.log(e);
      }
      if (!httpOptions.headers.has('X-USER-ID') && this.user().sub) {
          httpOptions.headers = httpOptions.headers.append(
              'X-USER-ID',
              this.user().sub
          );
      }

      if (!httpOptions.headers.has('x-pod-id') && pid) {
          httpOptions.headers = httpOptions.headers.append(
              'x-pod-id',
              pid
          );
      }

      let epu: any = this.baseURL;
      if (endPointUrl.indexOf('https://configuration') > -1 || endPointUrl.indexOf('https://auth') > -1) {
          epu = '';
      }
      // @ts-ignore
      httpOptions.headers.hashOptions = hashOptions
          ? hashOptions
          : { isLoading: false };
      return this.httpClient
          .request<NetaResponse>(method, `${epu}${endPointUrl}`, httpOptions)
          .pipe(map((response) => this.handleResponse(response)));
  }

  trimNested(input: any): any {
      if (typeof input === 'string') {
          return input.trim();
      } else if (typeof input === 'number') {
          return +(input.toString().trim());
      } else if (Array.isArray(input)) {
          return input.map(item => this.trimNested(item));
      } else if (typeof input === 'object' && input !== null) {
          return Object.fromEntries(
              Object.entries(input).map(([key, value]) => [key, this.trimNested(value)])
          );
      } else {
          return input;
      }
  }
  uploadFormData(endPointUrl: any, formData: any, isPut?: any): any {
      try {
          const token = JSON.parse(localStorage.getItem('zitadel:id_token'));
          const httpOptions: any = new HttpHeaders({
              'X-USER-ID': this.user().sub, 'AUTHORIZATION': `Bearer ${token}`,
              'x-pod-id': localStorage.getItem('_pid'),
              'X-TENANT-ID': localStorage.getItem('xtid')
          });
          // @ts-ignore
          httpOptions.hashOptions = { isLoading: false };
          let epu: any = this.baseURL;
          if (window.location.host.indexOf('dev') > -1) {
              epu = '';
          }
          let options = { headers: httpOptions };
          // @ts-ignore
          if (!isPut) {
              return this.httpClient.post<NetaResponse>(`${epu}${endPointUrl}`, formData, options)
                  .pipe(map((response) => this.handleResponse(response)));
          } else {
              return this.httpClient.put<NetaResponse>(`${epu}${endPointUrl}`, formData, options)
                  .pipe(map((response) => this.handleResponse(response)));
          }

      } catch (e) {
          console.log(e);
      }

  }
  getSnakeCaseName(camelCase: string): string {
      return camelCase
          .replace(/([A-Z])/g, '_$1')
          .toLowerCase()
          .replace(/^_(.*)/g, '$1');
  }

  private handleResponse(response: NetaResponse): NetaResponse {
      return response;
  }

  private handleError(error: HttpErrorResponse): any {
      if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
      } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`
          );
      }
      // return an observable with a user-facing error message
      return throwError('Something bad happened; please try again later.');
  }

  async getWhiteLabelSetting(): Promise<any> {
      try {
          const params: any = { condition: "company_id IS NULL and category='tenant' and name='white_label'", skip: 0, limit: 1 }
          const response = await this.doRequest(`/r/partners/settings`, 'get', null, params).toPromise();
          if (response.status) {
              if (response.data.length) {
                  const img = response.data[0].value;
                  this.whitelabel.set(
                      {
                          logo_dark: img.logo_url_dark || '/assets/images/logo/cs_logo_dark.png',
                          logo: img.logo_url || '/assets/images/logo/cs_logo_light.png',
                          icon: img.icon_url || '/assets/images/logo/cs_logo_only.png',
                          icon_dark: img.icon_url_dark || '/assets/images/logo/White_Turtle.png',
                          terms_of_service: img.terms_of_service || 'ConnectSecure',
                      }
                  )
                  try {
                      this.favIcon.href = img.icon_url || 'favicon-16x16.png';
                      this.favIconDark.href = img.icon_url || 'favicon-16x16.png';
                      if (img.page_title) {
                          this.titleService.setTitle(img.page_title);
                      } else {
                          this.titleService.setTitle('ConnectSecure :: Security and Vulnerability Manager');
                      }
                  } catch (e) {
                      this.favIcon.href = '/favicon-16x16.png';
                      this.favIconDark.href = '/favicon-16x16.png';
                  }
              } else {
                  this.whitelabel.set(
                      {
                          logo_dark: '/assets/images/logo/cs_logo_dark.png',
                          logo: '/assets/images/logo/cs_logo_light.png',
                          icon:  '/assets/images/logo/cs_logo_only.png',
                          icon_dark: '/assets/images/logo/White_Turtle.png',
                          terms_of_service:  'ConnectSecure',
                      }
                  );
                  this.favIcon.href = '/favicon-16x16.png';
                  this.favIconDark.href = '/favicon-16x16.png';
                  this.titleService.setTitle('ConnectSecure :: Security and Vulnerability Manager');
              }
          }
      } catch (error) {
          // Handle errors here
          throw error;
      }
  }

  async getTablesetting(): Promise<any> {
      try {
          const params: any = { condition: "company_id IS NULL and category='global' and sub_category='settings' and name='global_table'", skip: 0, limit: 100 }
          const response = await this.doRequest(`/r/partners/settings`, 'get', null, params).toPromise();
          if (response.status) {
              if (response.data && response.data.length) {
                  this.Tabelsetting.set({
                      font: response.data[0].value.font,
                      page_size: response.data[0].value.page_size
                  });
              } else {
                  this.Tabelsetting.set({
                      font: 'small',
                      page_size: '5'
                  });
              }
          }
      } catch (error) {
          // Handle errors here
          throw error;
      }
  }
}
function takeUntil(
  _unsubscribeAll: any
): import('rxjs').OperatorFunction<any, unknown> {
  throw new Error('Function not implemented.');
}
