<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="bg-card dark:bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <!-- <div class="flex items-center justify-center h-20 p-2 mt-2">
                <img
                    class="w-26 dark:hidden"
                    [src]="_bs.whitelabel().logo" aria-label="logo"
                    alt="Logo image">
                <img
                    class="w-26 hidden dark:flex"
                    [src]="_bs.whitelabel().logo_dark" aria-label="logo"
                    alt="Logo image">
            </div> -->
            <div class="flex items-center justify-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <!-- Light version -->
                    <img
                        class="dark:hidden  max-w-[12rem] h-[2.5rem] object-contain"
                        [src]="_bs.whitelabel().logo" aria-label="light"
                        alt="Logo image">
                    <!-- Dark version -->
                    <img
                        class="hidden dark:flex  max-w-[12rem] h-[2.5rem] object-contain"
                        [src]="_bs.whitelabel().logo_dark" aria-label="dark"
                        alt="Logo image">
                    <!--<div class="flex flex-row items-end justify-end">
                    <mat-icon matTooltip="Change Logo" [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
                </div>-->
                </div>
                <!-- Small version -->

                <div class="flex lg:hidden">
                    <!-- Light version -->
                    <img
                        class="dark:hidden  max-w-[10rem] h-[2.5rem] object-contain"
                        [src]="_bs.whitelabel().logo" aria-label="light"
                        alt="Logo image">
                    <!-- Dark version -->
                    <img
                        class="hidden dark:flex  max-w-[10rem] h-[2.5rem] object-contain"
                        [src]="_bs.whitelabel().logo_dark" aria-label="dark"
                        alt="Logo image">
                    <!--<div class="flex flex-row items-end justify-end">
                    <mat-icon matTooltip="Change Logo" [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
                </div>-->
                </div>

            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!--<notifications></notifications>-->
                <user></user>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <!-- <div class="relative w-24 h-24">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user.avatar"
                    [src]="user.avatar"
                    alt="User avatar">
                <mat-icon
                    class="icon-size-24"
                    *ngIf="!user.avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div> -->
            <!-- <div class="flex flex-col items-center justify-center w-full mt-2">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
            </div> -->
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img
                        class="dark:hidden  max-w-[12rem] h-[2.5rem] object-contain"
                        [src]="_bs.whitelabel().logo" aria-label="light"
                        alt="Logo image">
                    <!-- Dark version -->
                    <img
                        class="hidden dark:flex  max-w-[12rem] h-[2.5rem] object-contain"
                        [src]="_bs.whitelabel().logo_dark" aria-label="dark"
                        alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <app-add-partners></app-add-partners>
            <app-theme-switch class="hidden md:block"></app-theme-switch>
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
            <!-- <search [appearance]="'bar'"></search> -->

            <!--<new-company></new-company>-->
            <scope></scope>
            <button
                        class="lg:hidden"
                        mat-icon-button
                        (click)="dynamicSettings.toggle()">
                        <mat-icon [svgIcon]="'heroicons_outline:adjustments-horizontal'"></mat-icon>
                    </button>
            <!--<messages></messages>-->
            <!--<button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button>-->
        </div>
    </div>

    <!-- Content -->
    <dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings>
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <!--<router-outlet *ngIf="true"></router-outlet>-->
        <div class="flex flex-row items-center gap-2 text-gray-400 px-6 py-2 bg-card mb-1  shadow"
          *ngIf="currentPath && currentPath.length">
          <i (click)="redirectPage('/summary')" class="fas fa-home cursor-pointer hover:text-primary"></i>
          <span (click)="redirectPage('/summary')" class="font-semibold uppercase hover:text-primary ng-star-inserted rounded border cursor-pointer text-md bg-green-100 px-1
                 dark:bg-gray-800 dark:text-white py-0.5 text-green-900">
            <ng-container *ngIf="_cs.currentScope === '*'">GLOBAL</ng-container>
            <ng-container *ngIf="_cs.currentScope !== '*'">{{_cs.currentScope.distributor_name}}</ng-container>
          </span>
          <span *ngFor="let s of currentPath; let last = last"> {{navHash[s]|uppercase}} <i *ngIf="!last"
              class="ml-1 fas fa-chevron-right"></i></span>
        </div>
        <router-outlet>
    
        </router-outlet>
        <!-- <app-skeleton *ngIf="_cs.isComLoading">
    
        </app-skeleton> -->
      </div>
    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">ConnectSecure &copy; {{currentYear}}</span>
    </div>-->

</div>


<!-- <dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings> -->
