/* eslint-disable */
import { FuseNavigationItem } from "@fuse/components/navigation";
import cloneDeep from "lodash-es/cloneDeep";

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: "summary",
    title: "Summary",
    type: "basic",
    icon: "mat_outline:view_compact",
    link: "/summary",
    api: "",
  },
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   type: "basic",
  //   icon: "mat_outline:dashboard",
  //   link: "/dashboard",
  //   api: "",
  // },
  {
    id: "partner-management",
    title: "Partner Management",
    type: "basic",
    icon: "mat_outline:policy",
    link: "/partner-management",
    api: "",
  },
  // {
  //   id: "partner-management",
  //   title: "Partner Management",
  //   subtitle: "",
  //   type: "group",
  //   icon: "mat_outline:policy",
  //   api: "",
  //   link: "",
  //   exactMatch: true,
  //   children: [
  //     {
  //       id: "partner-management.all-partners",
  //       title: "All Partners",
  //       type: "basic",
  //       icon: "heroicons_mini:user-group",
  //       link: "/partner-management/all-partners",
  //       api: "partner-management",
  //       exactMatch: true,
  //     },
  //     {
  //       id: "partner-management.trial-partners",
  //       title: "Trial Partners",
  //       type: "basic",
  //       icon: "heroicons_mini:user",
  //       link: "/partner-management/trial-partners",
  //       api: "trial-partners",
  //       exactMatch: true,
  //     },
  //     {
  //       id: "partner-management.plan-partners",
  //       title: "Plan 100 Partners",
  //       type: "basic",
  //       icon: "heroicons_mini:users",
  //       link: "/partner-management/plan-partners",
  //       api: "plan-partners",
  //       exactMatch: true,
  //     },
  //     {
  //       id: "partner-management.paid-partners",
  //       title: "Paid Partners",
  //       type: "basic",
  //       icon: "heroicons_mini:user-plus",
  //       link: "/partner-management/paid-partners",
  //       api: "paid-partners",
  //       exactMatch: true,
  //     },
  //     {
  //       id: "partner-management.suspended-partners",
  //       title: "Suspended Partners",
  //       type: "basic",
  //       icon: "heroicons_mini:user-minus",
  //       link: "/partner-management/suspended-partners",
  //       api: "suspended-partners",
  //       exactMatch: true,
  //     },
  //     {
  //       id: "partner-management.cancelled-partners",
  //       title: "Cancelled Partners",
  //       type: "basic",
  //       icon: "heroicons_mini:user-minus",
  //       link: "/partner-management/cancelled-partners",
  //       api: "cancelled-partners",
  //       exactMatch: true,
  //     },
  //   ],
  // },
  {
    id: "global-settings",
    title: "Global Settings",
    subtitle: "",
    type: "group",
    icon: "mat_outline:settings",
    api: "global-settings",
    link: "/global-settings",
    exactMatch: true,
    children: [
      {
        id: "global-settings.partner-users",
        title: "Users",
        type: "basic",
        icon: "heroicons_mini:user-group",
        link: "/global-settings/partner-users",
        api: "partner-users",
        exactMatch: true,
      },
      {
        id: "global-settings.partner-distributors",
        title: "Distributors",
        type: "basic",
        icon: "mat_solid:vertical_distribute",
        link: "/global-settings/partner-distributors",
        api: "partner-distributors",
        exactMatch: true,
      },
      {
        id: "global-settings.partner-roles",
        title: "Roles",
        type: "basic",
        icon: "mat_solid:approval",
        link: "/global-settings/partner-roles",
        api: "partner-roles",
        exactMatch: true,
      },
      {
        id: "global-settings.partner-audit",
        title: "Audit Logs",
        type: "basic",
        icon: "heroicons_outline:calendar",
        link: "/global-settings/partner-audit",
        api: "partner-audit",
        exactMatch: true,
      },
      // {
      //   id: "global-settings.partner-settings",
      //   title: "Settings",
      //   type: "basic",
      //   icon: "mat_outline:settings",
      //   link: "/global-settings/partner-settings",
      //   api: "partner-settings",
      //   exactMatch: true,
      // },
    ],
  },
];

const compact: any = cloneDeep(defaultNavigation);
compact.map((x: any) => {
  if (x.children) {
    x.children = [];
    x.type = "aside";
  } else {
    x.type = "basic";
  }
});
export const compactNavigation: FuseNavigationItem[] = compact;

export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;

const horizontal: any = cloneDeep(defaultNavigation);
horizontal.map((x: any) => {
  x.children ? (x.children = []) : null;
});
export const horizontalNavigation: FuseNavigationItem[] = horizontal;
