<div class="w-auto h-auto absolute right-6" [ngClass]="{'mt-8': cs.checkLayout === 'enterprise'}"  *ngIf="!cs.hideDynamic">
    <div class="flex flex-row w-full sm:h-auto sm:w-full transition-transform duration-400 ease-drawer">
        <div class="flex-0 w-full h-auto overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
            fuseScrollbar [fuseScrollbarOptions]="{wheelPropagation: true}">
            <div class="flex flex-row items-center">
                <ng-container *ngFor="let action of actions; trackBy: trackByFn">
                    <div class="flex items-center py-1 px-2 cursor-pointer"
                         [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedAction || selectedAction.id !== action.id,
                                    'bg-primary-50 dark:bg-hover': selectedAction && selectedAction.id === action.id}"
                        (click)="selectAction(action)">
                        <div class="relative flex flex-0 items-center justify-center h-8">
                            <ng-container *ngIf="action.unreadCount > 0">
                                <div class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5
                                 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                                    [class.ring-primary-50]="selectedAction && selectedAction.id === action.id"></div>
                            </ng-container>
                            <ng-container *ngIf="!action.isSubMenu">
                                <ng-container *ngIf="action.icon">
                                    <div class="flex items-center justify-center gap-1
                                     w-full h-full rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        <mat-icon color="primary" class="icon-size-4"
                                            [ngClass]="{'primary': selectedAction && selectedAction.id === action.id}"
                                            [svgIcon]="action.icon"></mat-icon>
                                        <span class="text-xs">{{action.text}}</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!action.icon">
                                    <div class="mat-primary flex items-center justify-center w-full h-full
                                     rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        {{action.name.charAt(0)}}
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="action.isSubMenu">
                                <app-dynamic-menu [data]="action"></app-dynamic-menu>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div class="flex items-center py-1 px-2 cursor-pointer">
                    <settings></settings>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="fixed lg:sticky sm:sticky md:sticky xs:sticky top-0 bottom-0 w-16 max-h-[88vh] shadow" *ngIf="false && !cs.hideDynamic">
    <div class="flex flex-col w-full sm:w-16 h-full transition-transform duration-400 ease-drawer bg-card">
        <div class="flex-0 w-16 h-full overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
            fuseScrollbar [fuseScrollbarOptions]="{wheelPropagation: true}">
            <div class="flex items-center justify-center w-full h-14 border-b text-lg uppercase">
                <!--<mat-icon [svgIcon]="'tune'"></mat-icon> -->
                <settings></settings>
            </div>
            <div class="flex-auto ">
                <ng-container *ngFor="let action of actions; trackBy: trackByFn">
                    <div class="flex items-center py-3 px-4 cursor-pointer" [id]="action.id"
                         [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedAction || selectedAction.id !== action.id,
                                    'bg-primary-50 dark:    bg-hover': selectedAction && selectedAction.id === action.id}"
                         (click)="selectAction(action)">
                        <div class="relative flex flex-0 items-center justify-center w-8 h-8" id="{{action.action.name}}action">
                            <ng-container *ngIf="action.unreadCount > 0">
                                <div class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                                    [class.ring-primary-50]="selectedAction && selectedAction.id === action.id"></div>
                            </ng-container>
                            <ng-container *ngIf="!action.isSubMenu">
                                <ng-container *ngIf="action.icon">
                                    <div class="flex items-center justify-center
                                     w-full h-full rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        <mat-icon
                                            [ngClass]="{'primary': selectedAction && selectedAction.id === action.id}"
                                            [svgIcon]="action.icon"></mat-icon>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!action.icon">
                                    <div class="mat-primary flex items-center justify-center w-full h-full
                                     rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        {{action.name.charAt(0)}}
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="action.isSubMenu">
                                <app-dynamic-menu [data]="action"></app-dynamic-menu>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Select action or start new template -->
<ng-template #selectChatOrStartNew>
    <div class="flex flex-col flex-auto items-center justify-center w-full h-full p-4">
        <mat-icon class="icon-size-24" [svgIcon]="'heroicons_outline:cube-transparent'"></mat-icon>
        <div class="mt-4 text-xl text-center font-medium tracking-tight text-secondary">Select an action</div>
    </div>
</ng-template>

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
                fill="currentColor" fill-rule="nonzero"></path>
        </g>
    </svg>
</ng-template>
<!-- @formatter:on -->

<fuse-drawer class="w-screen min-w-screen z-999 fixed sm:w-3/4 sm:min-w-3/4" [mode]="'over'" [ngClass]="{'sm:w-3/4 sm:min-w-3/4': allowedId.indexOf(selectedAction?.id) === -1,
                     'sm:w-[40%] sm:min-w-[40%]': allowedId.indexOf(selectedAction?.id) > -1}"
    [name]="'dynamicSettingsDrawer'" [position]="'right'" (openedChanged)="onOpenedChange($event)" #dynamicSettingsDrawer>
    <div class="flex flex-col w-full bg-card">
        <div id="swami" class="flex flex-row items-center px-4 h-20 min-h-20 text-white bg-primary z-10"
            *ngIf="selectedAction">
            <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                <ng-container *ngIf="selectedAction.icon">
                    <div
                        class="flex items-center justify-center text-white w-full h-full rounded-full text-lg uppercase">
                        <mat-icon [svgIcon]="selectedAction.icon" class="text-white"></mat-icon>
                    </div>
                </ng-container>
                <ng-container *ngIf="!selectedAction.icon">
                    <div class="flex items-center justify-center w-full h-full rounded-full text-lg
                     uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                        {{selectedAction.name.charAt(0)}}
                    </div>
                </ng-container>
            </div>
            <div class="ml-3 text-2xl font-semibold tracking-tight">{{selectedAction.name}}</div>
            <button class="ml-auto" mat-icon-button (click)="closeDrawer()">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        <div class="flex flex-row w-full">
            <div *ngIf="!cs.hideDynamic && false" class="flex-0 w-16 border-r max-h-[88vh]" fuseScrollbar [fuseScrollbarOptions]="{wheelPropagation: true}">
                <div class="flex-auto">
                    <ng-container *ngFor="let action of actions; trackBy: trackByFn">
                        <div class="flex items-center py-3 px-4 cursor-pointer" *ngIf="selectedAction" [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedAction || selectedAction.id !== action.id,
                                        'bg-primary': selectedAction && selectedAction.id === action.id}"
                            (click)="selectAction(action)">
                            <div class="relative flex flex-0 items-center justify-center w-8 h-8">
                                <ng-container *ngIf="action.unreadCount > 0">
                                    <div class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                                        [class.ring-primary-50]="selectedAction && selectedAction.id === action.id">
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="action.icon">
                                    <div class="flex items-center justify-center
                                     w-full h-full rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        <mat-icon
                                            [ngClass]="{'text-white': selectedAction && selectedAction.id === action.id}"
                                            [svgIcon]="action.icon"></mat-icon>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!action.icon">
                                    <div class="mat-primary flex items-center justify-center w-full h-full
                                     rounded-full text-lg uppercase" [matTooltip]="action.name">
                                        {{action.name.charAt(0)}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</fuse-drawer>





