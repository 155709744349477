import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Route, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';

import { SearchComponent } from 'app/layout/common/search/search.component';

import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import {ScopeComponent} from "../../../common/scope/scope.component";
import {DynamicSettingsComponent} from "../../../common/dynamic-settings/dynamic-settings.component";
import { CommonService } from 'app/_services/common.service';
import { BaseRequestService } from 'app/_services/base.service';
import { cloneDeep } from 'lodash';
import { ThemeSwitchComponent } from "../../../../../@fuse/components/theme-switch/theme-switch.component";
import { AddPartnersComponent } from "../../../../modules/admin/add-partners/add-partners.component";

@Component({
    selector: 'dense-layout',
    templateUrl: './dense.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, FuseVerticalNavigationComponent, MatButtonModule, MatIconModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, MessagesComponent, NotificationsComponent, UserComponent, NgIf, RouterOutlet, ScopeComponent, DynamicSettingsComponent, ThemeSwitchComponent, AddPartnersComponent]
})
export class DenseLayoutComponent implements OnInit, OnDestroy
{
    navigation: Navigation;
    _overall_navigation: Navigation;
    currentCompany: any;
    isScreenSmall: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    navHash: any = {};
    currentPath: any;
    navigationAppearance: 'default' | 'dense' = 'dense';
    private subs: Subscription;
    _hovered: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public _bs: BaseRequestService,
        public _cs: CommonService
    )
    {
        this.subs = this._cs.selectedSiteChanged.subscribe((cmp: any) => {
            this.initNav(cmp);
          });
          _router.events
            .pipe(filter((event: any) => event instanceof NavigationEnd))
            .subscribe((val: any) => {
              this.currentPath = val.url.split("/");
            });
          this.getNavLinks(this._router.config);
          setTimeout(() => {
            try {
              if (this._activatedRoute.snapshot["_routerState"]) {
                this.currentPath =
                  this._activatedRoute.snapshot["_routerState"].url?.split("/");
              }
            } catch (e) {
              setTimeout(() => {
                this.currentPath =
                  this._activatedRoute.snapshot["_routerState"].url?.split("/");
              });
            }
          });
    }
    getNavLinks(routes: Route[]) {
        routes.forEach((x) => {
          if (x.children) {
            this.getNavLinks(x.children);
          }
          if (x.data && x.data.breadcrumb) {
            this.navHash[x.path] = x.data.breadcrumb.alias;
          }
        });
      }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this.initNav();
    
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes("md");
          });
      }
      initNav(filter?: any): void {
        this._navigationService.navigation$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((navigation: Navigation) => {
            if (!this._overall_navigation) {
              this._overall_navigation = navigation;
            }
            const navi = cloneDeep(this._overall_navigation);
            if (filter === "*") {
              Object.keys(navi).forEach((obj: any) => {
                const filterById = (arr: any, idsToRemove: any) =>
                  arr.filter(
                    (item: any) =>
                      !idsToRemove.includes(item.id) &&
                      (!item.children ||
                        (item.children = filterById(item.children, idsToRemove)))
                  );
                navi[obj] = filterById(navi[obj], [
                ]);
              });
            } else {
              Object.keys(navi).forEach((obj: any) => {
                const filterById = (arr: any, idsToRemove: any) => arr.filter((item: any) => (!idsToRemove.includes(item.id) && (!item.children || (item.children = filterById(item.children, idsToRemove)))));
                navi[obj] = filterById(navi[obj], ['global-settings']);
              });
            }
            this.navigation = navi;
          });
      }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this.subs.unsubscribe();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void
    {
        this.navigationAppearance = (this.navigationAppearance === 'default' ? 'dense' : 'default');
    }
    hoverChanged($event: any): void {
        this._hovered = $event;
    }
    redirectPage(page: any): void {
        this._router.navigate([page]);
    }
}
