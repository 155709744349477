import { Route, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable, of, takeUntil } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { StatehandlerService } from './statehandler.service';
import { UserService } from 'app/core/user/user.service';
import { BaseRequestService } from "../_services/base.service";

@Injectable({
    providedIn: 'root',
})

export class AuthenticationService {
    private _authenticated: boolean = false;
    private clientId; private orgId;
    private readonly _authenticationChanged: BehaviorSubject<boolean> =
        new BehaviorSubject(this.authenticated);

    constructor(
        private oauthService: OAuthService,
        private authConfig: AuthConfig,
        private statehandler: StatehandlerService,
        private _bs: BaseRequestService,
        private uService: UserService,
        private router: Router,
    ) {
        this.clientId = localStorage.getItem('_tid');
    }

    public get authenticated(): boolean {
        return this._authenticated;
    }
    updateAuth(state: boolean): void {
        this._authenticated = state;
        if (this._authenticated) {
            var token = localStorage.getItem('zitadel:id_token');
            this.uService.userd$ = of(jwt_decode(token));
            this.uService._user_roles = Object.keys(jwt_decode(token)["urn:zitadel:iam:org:project:roles"]);
        }
    }

    public get authenticationChanged(): Observable<boolean> {
        return this._authenticationChanged;
    }

    public getOIDCUser(): Observable<any> {
        return from(this.oauthService.loadUserProfile());
    }

    updateCID(result: any): void {
        this.clientId = result.message.split('#$#$')[0];
        localStorage.setItem('_tid', result.message.split('#$#$')[0]);
    }

    updateClientID(result: any, isIDP?: boolean): void {
        this.clientId = result.message.client_id;
        this.orgId = result.message.tenant_id;
        localStorage.setItem('_tid', this.clientId);
        localStorage.setItem('_pid', result.message.pod_id);
        localStorage.setItem('_tInfo', btoa(JSON.stringify(result.message)));
        this.authConfig.clientId = this.clientId;
        if (isIDP) {
            this.authConfig.scope += ' urn:zitadel:iam:org:id:' + this.orgId;
        }
    }

    public async authenticate(setState: boolean = true): Promise<boolean> {
        if (!this.clientId) {
            this.router.navigateByUrl('/sign-in');
            this._authenticated = false;
            localStorage.clear();
            sessionStorage.clear();
            return false;
        }
        this.authConfig.clientId = this.clientId;
        this.oauthService.configure(this.authConfig);
        this._authenticated = this.oauthService.hasValidAccessToken();
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.strictDiscoveryDocumentValidation = false;
        await this.oauthService.loadDiscoveryDocumentAndTryLogin();
        /*if (this.authenticated) {
            var token = localStorage.getItem('zitadel:id_token');
            this.uService.userd$ = of(jwt_decode(token));
            this.uService._user_roles = Object.keys(jwt_decode(token)["urn:zitadel:iam:org:project:roles"]);
        }
        if (!this.oauthService.hasValidIdToken() || !this.authenticated) {
            const newState = setState
                ? await this.statehandler.createState().toPromise()
                : undefined;
            this.oauthService.initCodeFlow(newState);
        }*/
        const newState = setState
            ? await this.statehandler.createState().toPromise()
            : undefined;
        this.oauthService.initCodeFlow(newState);
        return this.authenticated;
    }

    public signout(): void {
        const token = JSON.parse(localStorage.getItem('zitadel:id_token'));
        this._bs.doRequest(`/d/user/logout`, 'post', {}).subscribe((res: any) => {
            this.oauthService.logOut();
            this._authenticated = false;
            localStorage.clear();
            sessionStorage.clear();
            const id_token = '';
            const redirect_uri = `${window.location.origin}/sign-in`
            window.location.href = `${this._bs.authURL}/oidc/v1/end_session?id_token_hint=${token}
            &post_logout_redirect_uri=${redirect_uri}&state=logout`;
        }, (error: any) => {
            this.oauthService.logOut();
            this._authenticated = false;
            localStorage.clear();
            sessionStorage.clear();
        });
        this.oauthService.logOut();
        this._authenticated = false;
        localStorage.clear();
        sessionStorage.clear();
        // this._authenticationChanged.next(false);
    }

    check(): Observable<boolean> {
        if (this.authenticated) {
            return of(true);
        } else {
            this.authenticate();
        }
        return of(false);
    }
}
